div.chart-wrapper {
  background-color: white;
  padding: 24px 0;
  margin: auto;
  margin-top: 96px;
  div.chart {
    height: 80px;
  }
  form.inputs {
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding: 0 24px;
    span.spacer {
      display: block;
      padding: 0 12px;
    }
  }
}
