div.input-wrapper {
  padding: 8px 16px;
  border: 1px solid #d7dfe9;
  font-family: 'Futura', sans-serif;
  flex: 1;
  input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-family: 'Futura', sans-serif;
    padding: 0;
    padding-left: 16px;
    padding-bottom: 8px;
    margin-left: -16px;
    margin-bottom: -8px;
  }
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #666;
    font-weight: 400;
  }
}