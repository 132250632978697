$background: rgb(91, 91, 91);
$primary: rgb(232, 28, 32);

@import url(http://fonts.googleapis.com/css?family=Open+Sans);
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  background: #eee;
  font: 13px/1.5em 'Open Sans', Helvetica, Arial, sans-serif;
}