$grey: rgb(91, 91, 91);
$primary: rgb(232, 28, 32);

div.slider-outer-wrapper {
  padding-bottom: 16px;
  div.slider-input-wrapper {
    display: flex;
    align-items: center;
    div.line {
      position: relative;
      height: 2px;
      flex: 1;
      background-color: rgb(230, 230, 230);
      div.highlight {
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: $primary;
        transition: none;
      }
    }
  
    div.knob-wrapper {
      user-select: none;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 12;
      transition: none;
      &:hover, &:active {
        div.knob {
          box-shadow: 0 0 8px 0px rgba($primary, .4);
        }
        div.knob-label {
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, -100%);
        }
      }
      &:active {
        div.knob {
          cursor: grabbing;
        }
      }
      div.knob {
        width: 30px;
        height: 30px;
        border-radius: 500px;
        background-color: white; 
        cursor: grab;
        border: 2px solid $primary;
      }
      div.knob-label {
        position: absolute;
        pointer-events: none;
        top: -12px;
        transform: translate(-50%, calc(-100% + 8px));
        display: flex;
        padding: 4px 12px;
        white-space: nowrap;
        left: 50%;
        background: white;
        border: 1x solid $primary;
        border-radius: 3px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}